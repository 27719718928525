import React from "react";
import ButtonAppStore from "src/components/ButtonAppStore";

const Footer = () => {
  return (
    <div>
      <div className="top-footer__wrapper">
        <div className="container top-footer">
          <div className="top-footer__btn-wrapper">
            <ButtonAppStore />
          </div>
        </div>
      </div>
      <div className="footer__wrapper">
        <div className="container footer">
          <div className="footer__copyright">
            <span>Lapsi Soft LLC. All rights reserved.</span>
            <span>Contact Us: 
              <a href="mailto:hello@lapsi.app" className="footer__copyright-email">hello@lapsi.app</a>
            </span>
            <span>+1 (737) 234 5111</span>
          </div>
          <div className="footer__social">
            <a href="https://www.instagram.com/lapsi.app/" className="footer__social-icon icon-instagram" target="_blank" rel="noopener noreferrer" />
            <a href="https://facebook.com/lapsiapp/" className="footer__social-icon icon-facebook" target="_blank" rel="noopener noreferrer" />
            <a href="https://www.pinterest.com/LapsiApp" className="footer__social-icon icon-pinterest" target="_blank" rel="noopener noreferrer" />
            {/* <a href="https://www.youtube.com/channel/UCzfhAmjenjjhVNVzTOj5VLA" className="footer__social-icon icon-youtube" target="_blank" rel="noopener noreferrer" /> */}
          </div>
          <div className="footer__privacy-policy">
            <span>
              <a
                href="https://www.notion.so/Terms-and-Conditions-Lapsi-App-09246b4084a643d9b9c73458703b90aa"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              <a
                href="https://www.notion.so/aknaham/Privacy-Policy-Lapsi-LLC-69e575acd4894127ad092be829203b52"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </div> 
        </div>
      </div>
    </div>
  )
}

export default Footer
