import React from 'react';
import btnAppStoreText from "src/images/app_store_btn_text.svg";


const ButtonAppStore = () => {
  return (
      <a
        className="btn__app-store"
        href="https://apps.apple.com/app/lapsi-app/id1630301919"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={btnAppStoreText} width="126px" height="32px" alt="app_store_text"/>
      </a>
  );
}

export default ButtonAppStore;
