import React, {useState} from 'react';
import { useLocation } from "@reach/router";
import BabyMenu from "src/images/baby_menu.svg";

const BurgerMenu = ({children}) => {
  const { href: currentHref } = useLocation();
  const [isShowMenu, setIsShowMenu] = useState(false)
  const handleMenu = () => {
    setIsShowMenu(!isShowMenu);
  };

  const handleClick = (e) => {
    const itemMenuHref = e?.target?.href;
    
    if(itemMenuHref === currentHref) {
      setIsShowMenu(false);
    };
  };

  return (
    <div onClick={handleClick}>
      <span className="burger-btn__wrapper" onClick={handleMenu}>
        <span className="burger-btn"></span>
      </span>
      <div className={`burger__menu__wrapper ${!isShowMenu && 'burger__menu__wrapper--hidden'}`}>
          <div className="burger__menu">
            <span className="burger-close-btn__wrapper" onClick={handleMenu}>
              <span className="burger-close-btn"></span>
            </span>
            {children}
            <img src={BabyMenu} alt="baby-menu"/>
          </div>
        </div> 
    </div>
  );
}

export default BurgerMenu;
